<template>
  <div class="contain">
    <div>
      <el-page-header @back="goBack" content="赛事创建">
      </el-page-header>
    </div>
    <div class="message-form">
      <div class="form-box">
        <span style="margin-right: 20px">比赛名称 :</span>
        <el-input style="width: 500px" v-model="createForm.eventName" placeholder="请输入比赛名称"
                  size="small"></el-input>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">比赛简介 :</span>
        <el-input style="width: 500px" v-model="createForm.eventDesc" type="textarea" :rows="3"
                  placeholder="请输入比赛简介" size="small"></el-input>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">赛事封面 :</span>
        <el-upload
            class="avatar-uploader"
            :action=uploadUrl
            :show-file-list="false"
            :headers=myHeaders
            :on-success="handleAvatarSuccessCover"
            :before-upload="beforeAvatarUpload">
          <img v-if="createForm.eventCover" :src="createForm.eventCover" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">字体大小 :</span>
        <el-select v-model="createForm.fontSize" placeholder="请选择字体大小" size="mini">
          <el-option

              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">字体颜色 :</span>
        <el-color-picker v-model="createForm.fontColor"></el-color-picker>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">背景颜色 :</span>
        <el-color-picker v-model="createForm.bgColor"></el-color-picker>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">背景图片 :</span>
        <el-upload
            class="avatar-uploader"
            :action=uploadUrl
            :show-file-list="false"
            :headers=myHeaders
            :on-success="handleBgImgSuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="createForm.bgImg" :src="createForm.bgImg" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="form-box">
        <span style="margin-right: 20px">评分标准 :</span>
        <el-radio-group v-model="createForm.scoreStandard" @input="changeChoose">
          <el-radio :label="1">广场舞评分</el-radio>
          <el-radio :label="2">健身气功评分</el-radio>
          <el-radio :label="3">气舞评分</el-radio>
          <el-radio :label="4">其他评分</el-radio>
        </el-radio-group>
      </div>


      <div class="choose-box" v-show="createForm.scoreStandard === 1">
        <p style="color:#999;">说明：管理员设置分数上限,裁判根据分数上限进行打分,打分完毕后,系统平均分为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="createForm.subAddScore">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">分数上限 : </span>
          <el-input v-model="createForm.maxScore" style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
      <div class="choose-box" v-show="createForm.scoreStandard === 2">
        <p style="color:#999;">
          说明：管理员设置AB组分数上限,裁判根据分数上限进行打分,打分完毕后,系统分别去AB组的平均分之和为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="createForm.subAddScore">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">A组分数上限&#12288; : </span>
          <el-input v-model="createForm.maxScoreA" style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">B组分数上限&#12288; : </span>
          <el-input v-model="createForm.maxScoreB" style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
      <div class="choose-box" v-show="createForm.scoreStandard === 3">
        <p style="color:#999;">
          说明：管理员设置AB组分数上限,裁判根据分数上限进行打分,打分完毕后,系统按照A组占60%,B组占40%分别取AB组的平均分之和为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="createForm.subAddScore">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px;color: #999">
          <span style="margin-right: 10px">A组分数上限&#12288; : </span>
          <el-input v-model="createForm.maxScoreA" style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">B组分数上限&#12288; : </span>
          <el-input v-model="createForm.maxScoreB" style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
      <div class="choose-box" v-show="createForm.scoreStandard === 4">
        <p style="color:#999;">
          说明：管理员设置分数上限,裁判根据分数上限进行打分,打分完毕后,系统取平均分或总分平均分为参赛者得分</p>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">去掉最高分最低分 : </span>
          <el-radio-group v-model="createForm.subAddScore">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px" class="add-box">
          <span style="color: #999;margin-right: 10px">计分方式 : </span>
          <el-radio-group v-model="createForm.scoreStyle">
            <el-radio :label="1">总分</el-radio>
            <el-radio :label="0">平均分</el-radio>
          </el-radio-group>
        </div>
        <div style=" display: flex;align-items: center;margin-top: 20px">
          <span style="color: #999;margin-right: 10px">单个分数上限 : </span>
          <el-input v-model="createForm.maxScore" style="width: 300px" placeholder="请输入分数上限"
                    size="small"></el-input>
        </div>
      </div>
    </div>

    <div class="contain-title">
      <h3>参赛选手</h3>
      <el-button style="background-color: #bf353b;color: #fff" size="small" @click="openPlayerDialog(null,null)">
        添加选手
      </el-button>
    </div>
    <div class="contain-table">
      <el-table
          :data="playerData"
          :header-cell-style="{ background:'#b14040',color:'#fff'}"
          border
          style="width: 100%">
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.avatarUrl"
                 style="width: 50px;height: 50px;border-radius: 50%"
                 alt="">
          </template>
        </el-table-column>
        <el-table-column prop="descContent" label="简介" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="openPlayerDialog(scope.row,scope.$index)">编 辑</el-button>
            <el-button type="text" @click="deletePlayers(scope.$index)">删 除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contain-title">
      <h3>裁判</h3>
      <el-button style="background-color: #bf353b;color: #fff" size="small" @click="openJudgesDialog(null,null)">
        添加裁判
      </el-button>
    </div>
    <div class="contain-table">
      <el-table
          :data="judgesData"
          :header-cell-style="{ background:'#b14040',color:'#fff'}"
          border
          style="width: 100%">
        <el-table-column label="裁判编号" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.judgeCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="裁判姓名" align="center">
          <template slot-scope="scope">
            <span style="color: #b14040">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="裁判头像" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.avatarUrl"
                 style="width: 50px;height: 50px;border-radius: 50%"
                 alt="">
          </template>
        </el-table-column>
        <el-table-column prop="descContent" label="简介" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="openJudgesDialog(scope.row,scope.$index)">编 辑</el-button>
            <el-button type="text" @click="deleteJudge(scope.$index)">删 除</el-button>
            <el-button v-if="scope.row.mainStatus === 0" type="text" @click="setJudge(scope.$index)">设为裁判长
            </el-button>
            <el-button v-else type="text" @click="quitJudge(scope.$index)">取消裁判长</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-btn">
      <el-button style="width: 150px; background-color: #bf353b;color: #fff" @click="createEvent">创建比赛</el-button>
    </div>

    <!--选手添加/编辑弹窗-->
    <el-dialog
        title="选手信息"
        :visible.sync="playerDialog"
        width="30%"
        center
    >
      <div class="dialog-form">
        <div class="dialog-title">
          <span style="margin-right: 20px">选手姓名 :</span>
          <el-input v-model="playerForm.name" style="width: 300px" placeholder="请输入选手姓名"
                    size="small"></el-input>
        </div>
        <div class="dialog-title">
          <span style="margin-right: 20px">选手简介 :</span>
          <el-input v-model="playerForm.descContent" style="width: 300px" type="textarea" :rows="2"
                    placeholder="请输入选手简介" size="small"></el-input>
        </div>
        <div class="dialog-title">
          <span style="margin-right: 20px">选手照片 :</span>
          <el-upload
              class="avatar-uploader"
              :action=uploadUrl
              :show-file-list="false"
              :headers=myHeaders
              :on-success="handleAvatarSuccessPlayer"
              :before-upload="beforeAvatarUpload">
            <img v-if="playerForm.avatarUrl" :src="playerForm.avatarUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button style="width: 100px ;background-color: #bf353b;color: #fff" @click="submitPlayer"
                   size="small">确 定</el-button>
			  </span>
    </el-dialog>
    <!--评委添加/编辑弹窗-->
    <el-dialog
        title="裁判信息"
        :visible.sync="judgesDialog"
        width="30%"
        center
    >
      <div class="dialog-form">
        <div class="dialog-title">
          <span style="margin-right: 20px">裁判编号 :</span>
          <el-input v-model="judgeForm.judgeCode" disabled style="width: 300px" placeholder="请输入裁判编号"
                    size="small"></el-input>
        </div>
        <div class="dialog-title">
          <span style="margin-right: 20px">裁判姓名 :</span>
          <el-input v-model="judgeForm.name" style="width: 300px" placeholder="请输入裁判姓名"
                    size="small"></el-input>
        </div>
        <div class="dialog-title">
          <span style="margin-right: 20px">裁判简介 :</span>
          <el-input style="width: 300px" v-model="judgeForm.descContent" type="textarea" :rows="2"
                    placeholder="请输入裁判简介" size="small"></el-input>
        </div>
        <div class="dialog-title">
          <span style="margin-right: 20px">裁判照片 :</span>
          <el-upload
              class="avatar-uploader"
              :action=uploadUrl
              :show-file-list="false"
              :headers=myHeaders
              :on-success="handleAvatarSuccessJudge"
              :before-upload="beforeAvatarUpload">
            <img v-if="judgeForm.avatarUrl" :src="judgeForm.avatarUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button style="width: 100px ;background-color: #bf353b;color: #fff" @click="submitJudge"
                   size="small">确 定</el-button>
			  </span>
    </el-dialog>
  </div>
</template>

<script>
import common from "../../utils/common";
import {createEvent} from "../../api/event";

export default {
  name: "createEvent",
  data() {
    return {
      uploadUrl: common.requestUrl + '/upload/uploadPic',
      myHeaders: {
        token: ''
      },
      options: [
        {
          name: '2vw',
          id: 2
        },
        {
          name: '3vw',
          id: 3
        },
        {
          name: '4vw',
          id: 4
        },
        {
          name: '5vw',
          id: 5
        },
      ],
      imageUrl: '',
      playerDialog: false,
      judgesDialog: false,
      createForm: {
        eventCover: "",
        eventDesc: "",
        eventName: "",
        scoreStandard: 1,
        subAddScore: 0,
        maxScore: null,
        maxScoreA: null,
        maxScoreB: null,
        scoreStyle: 0,
        playerList: [],
        judgeList: [],
        bgImg: null,
        bgColor: '#bf353b',
        fontColor: '#ffffff',
        fontSize: null
      },
      playerForm: {
        avatarUrl: "",
        descContent: "",
        name: "",
        roleFlag: 1
      },
      judgeForm: {
        avatarUrl: "",
        descContent: "",
        judgeCode: "",
        mainStatus: 0,
        name: "",
        roleFlag: 2
      },
      isAdd: null,
      isJudge: null,
      playerData: [],
      judgesData: []
    }
  },
  created() {
    this.myHeaders.token = sessionStorage.getItem('token')
  },
  methods: {
    goBack() {
      this.$router.push('/eventList')
    },
    handleAvatarSuccessCover(res, file) {
      this.createForm.eventCover = res.data
    },
    handleAvatarSuccessPlayer(res, file) {
      this.playerForm.avatarUrl = res.data
    },
    handleAvatarSuccessJudge(res, file) {
      this.judgeForm.avatarUrl = res.data
    },
    handleBgImgSuccess(res, file) {
      this.createForm.bgImg = res.data
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return (isJPG || isPNG) && isLt2M;
    },
    openPlayerDialog(item, val) {
      this.isAdd = val
      if (item) {
        this.playerForm = item
      }
      this.playerDialog = true
    },
    openJudgesDialog(item, val) {
      console.log(item)
      console.log(val)
      this.isJudge = val
      if (item) {
        this.judgeForm = item
      } else {
        if (this.judgesData.length === 0) {
          this.judgeForm.judgeCode = '0' + 1
        } else {
          for (let i = 0; i < this.judgesData.length; i++) {
            this.judgeForm.judgeCode = '0' + (i + 2)
          }
        }
      }

      this.judgesDialog = true
    },
    changeChoose(e) {
      console.log(e)
      if (e === 1) {
        this.createForm.maxScoreA = null
        this.createForm.maxScoreB = null
        this.createForm.scoreStyle = 0
      } else if (e === 2) {
        this.createForm.maxScore = null
        this.createForm.scoreStyle = 0
      } else if (e === 3) {
        this.createForm.maxScore = null
        this.createForm.scoreStyle = 0
      } else {
        this.createForm.maxScoreA = null
        this.createForm.maxScoreB = null
      }
    },
    //新增/编辑选手
    submitPlayer() {
      // for (let key in this.playerForm) {
      //     if (!this.playerForm[key]) {
      //         return this.$message.error('请将选手参数填写完整!!!!')
      //     }
      // }
      if (this.isAdd === null) {
        let obj = {
          avatarUrl: this.playerForm.avatarUrl,
          descContent: this.playerForm.descContent,
          name: this.playerForm.name,
          roleFlag: this.playerForm.roleFlag
        }
        this.playerData.push(obj)
        this.playerDialog = false
        this.playerForm.avatarUrl = ''
        this.playerForm.name = ''
        this.playerForm.descContent = ''
      } else {
        for (let i = 0; i < this.playerData.length; i++) {
          if (i === this.isAdd) {
            let objOne = {
              avatarUrl: this.playerForm.avatarUrl,
              descContent: this.playerForm.descContent,
              name: this.playerForm.name,
              roleFlag: this.playerForm.roleFlag
            }
            this.playerData[i] = objOne
            this.playerDialog = false
            console.log(this.playerData)
          }
        }
      }

    },
    //删除选手
    deletePlayers(val) {
      console.log(val)
      this.$confirm('删除该选手, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.playerData.length; i++) {
          if (i === val) {
            this.playerData.splice(i, 1)
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //新增/编辑评委
    submitJudge() {
      for (let key in this.judgeForm) {
        if (this.judgeForm[key] === null) {
          return this.$message.error('请将裁判参数填写完整!!!!')
        }
      }

      if (this.isJudge === null) {
        let obj = {
          judgeCode: this.judgeForm.judgeCode,
          avatarUrl: this.judgeForm.avatarUrl,
          descContent: this.judgeForm.descContent,
          name: this.judgeForm.name,
          mainStatus: this.judgeForm.mainStatus,
          roleFlag: this.judgeForm.roleFlag
        }
        this.judgesData.push(obj)
        this.judgesDialog = false
        this.judgeForm.avatarUrl = ''
        this.judgeForm.name = ''
        this.judgeForm.descContent = ''
      } else {
        for (let i = 0; i < this.judgesData.length; i++) {
          if (i === this.isJudge) {
            let objOne = {
              judgeCode: this.judgeForm.judgeCode,
              avatarUrl: this.judgeForm.avatarUrl,
              descContent: this.judgeForm.descContent,
              name: this.judgeForm.name,
              mainStatus: this.judgeForm.mainStatus,
              roleFlag: this.judgeForm.roleFlag
            }
            this.judgesData[i] = objOne
            this.judgesDialog = false

          }
        }
      }
      console.log(this.judgesData)
    },
    //	删除评委
    deleteJudge(val) {
      this.$confirm('删除该裁判, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.judgesData.length; i++) {
          if (i === val) {
            this.judgesData.splice(i, 1)
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //	设为主评委
    setJudge(val) {
      this.$confirm('设为裁判长, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.judgesData.length; i++) {
          if (i === val) {
            this.judgesData[i].mainStatus = 1
            this.$message({
              type: 'success',
              message: '设置成功!'
            });
          } else {
            this.judgesData[i].mainStatus = 0
          }
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消设置'
        });
      });
    },
    // 取消主评委
    quitJudge(val) {
      this.$confirm('取消裁判长, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.judgesData.length; i++) {
          if (i === val) {
            this.judgesData[i].mainStatus = 0
            this.$message({
              type: 'success',
              message: '设置成功!'
            });
          }
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消设置'
        });
      });
    },
    //创建比赛
    async createEvent() {
      console.log(this.createForm)
      this.createForm.playerList = this.playerData
      this.createForm.judgeList = this.judgesData
      let flag = true
      for (let i = 0; i < this.createForm.judgeList.length; i++) {
        if (this.createForm.judgeList[i].mainStatus === 1) {
          flag = false
          break
        }
      }
      if (flag) {
        return this.$message.warning('必须设置裁判长!!!')
      }
      const {data} = await createEvent(this.createForm)
      if (data.code === 200) {
        this.$message.success(data.message)
        // this.createForm = {}
        // this.playerData = []
        // this.judgesData = []
        this.goBack();
      }
    }
  }
}
</script>

<style scoped lang="less">
.contain {
  /*margin: 0px;*/
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 50px;
  box-sizing: border-box;
  background: url("../../assets/images/homeBanner.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;

  .dialog-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dialog-title {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      /deep/ .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }

      .avatar {
        width: 80px;
        height: 80px;
        display: block;
      }
    }
  }

  .message-form {
    width: 60%;
    margin: 30px auto;
    box-sizing: border-box;

    .form-box {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #bf353b;
        background-color: #bf353b;
      }

      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #bf353b;
      }

      /deep/ .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }

      .avatar {
        width: 200px;
        height: 100px;
        display: block;
      }
    }

    .add-box {
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #bf353b;
        background-color: #bf353b;
      }

      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #bf353b;
      }
    }

    .choose-box {
      width: 100%;
      padding: 10px 50px;
      box-sizing: border-box;
    }
  }

  .contain-title {
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contain-table {
    width: 100%;
  }

  .footer-btn {
    width: 100%;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
